<template>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->

  <div class="app">
    <!-- PC端 -->
    <div class="PC">
      <div class="header_box">
        <div class="header">
          <div class="header-logo">
            <img src="./image/qmf1.png" alt="" />
            <img src="./image/qmf.png" alt="" style="margin-left: 7px" />
            <!-- <img
        src="https://img.js.design/assets/img/67a47561b269b2492dffcd3a.png"
        alt=""
      /> -->
          </div>

          <div class="header-nav">
            <div
              v-for="(item, index) in navItems"
              :key="index"
              :class="{ active: activeIndex === index }"
              @click="toggleItem(index)"
            >
              <span>{{ item.name }}</span>

              <img
                v-show="index == 3"
                :src="
                  activeIndex == 3 && showDropdown
                    ? navItemsImg[1]
                    : navItemsImg[0]
                "
                alt=""
              />
            </div>
          </div>

          <div
            v-show="activeIndex === 3 && showDropdown"
            class="header-nav-dropdown"
          >
            <div @click="go('cz')">操作文档</div>
            <div class="line"></div>
            <div @click="go('cl')">策略文档</div>
            <div class="line"></div>
            <div @click="go('zb')">指标文档</div>
            <div class="line"></div>
            <div @click="go('jq')">机器学习</div>
            <!-- <div class="line"></div> -->
          </div>
        </div>
      </div>

      <!-- <router-view v-if="isPhone"></router-view> -->
      <router-view v-if="!isPhone"></router-view>
      <!-- <Home v-show="currentContentIndex === 0" />
      <Download v-show="currentContentIndex === 1" />
      <Membership v-show="currentContentIndex === 2" />
      <About v-show="currentContentIndex === 5" /> -->

      <!-- 侧边导航栏 -->
      <div class="right_nav">
        <div class="right_nav_item" @click="click_right_nav('微信咨询')">
          <div>
            <img src="./image/right_nav/微信@3x.png" alt="" />
          </div>
          <div>微信咨询</div>
        </div>
        <div class="right_nav_item" @click="click_right_nav('QQ咨询')">
          <div>
            <img src="./image/right_nav/qq@3x.png" alt="" />
          </div>
          <div>QQ咨询</div>
        </div>
        <div class="right_nav_item" @click="click_right_nav('商务合作')">
          <div>
            <img src="./image/right_nav/商务合作@3x.png" alt="" />
          </div>
          <div>商务合作</div>
        </div>
        <div class="right_nav_item" @click="click_right_nav('联系电话')">
          <div>
            <img src="./image/right_nav/侧边电话@3x.png" alt="" />
          </div>
          <div>联系电话</div>
        </div>
      </div>

      <div class="right_nav_wx" v-show="showWX">
        <img src="./image/right_nav/微信.png" alt="" />
        <div class="title">官方微信客服</div>
      </div>
      <div class="right_nav_content" v-show="showCooperate">
        <div>
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAlign"
            :rules="rules"
            ref="formRef"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wx">
              <el-input v-model="formLabelAlign.wx"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="formLabelAlign.phone"></el-input>
            </el-form-item>
            <el-form-item label="主体类型" prop="subjectType">
              <!-- <el-input v-model="formLabelAlign.subjectType"></el-input> -->
              <el-select
                v-model="formLabelAlign.subjectType"
                placeholder="请选择主体类型"
              >
                <el-option label="个人" value="个人"></el-option>
                <el-option label="期货公司" value="期货公司"></el-option>
                <el-option label="销售团队" value="销售团队"></el-option>
                <el-option label="投教老师" value="投教老师"></el-option>
                <el-option label="基金公司" value="基金公司"></el-option>
                <el-option label="证券公司" value="证券公司"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <button type="primary" v-loading="loading" @click="submitForm">
          立即预约
        </button>
      </div>
      <div class="right_nav_qq" v-show="showQQ">
        <img src="./image/right_nav/qq(1).png" alt="" />
      </div>
      <div class="right_nav_phone" v-show="showPhone">
        <div>13880072820</div>
        <div>
          <img @click="copy" src="./image/right_nav/复制@3x.png" alt="" />
        </div>
      </div>

      <div class="footer">
        <div class="footer-content">
          <div class="footer-left">
            <div class="footer-left-title">
              <div class="footer-left-icon">
                <span class="iconfont icon-baisedianhua"></span>
              </div>
              <div>
                <div class="footer-left-phone">028-60119342</div>
                <div class="footer-left-time">工作日: 9:00~18:00</div>
              </div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
              <!-- <el-button>默认按钮</el-button> -->
              <button @click="clickDownload" class="button1">软件下载</button>
              <button @click="open_showCooperate" class="button2">
                申请试用
              </button>
            </div>
          </div>

          <div class="footer-center">
            <div v-for="(item, index) in footerList" :key="index">
              <div class="footer-center-item">
                <div class="footer-title">{{ item.title }}</div>
                <div v-for="(uitem, uindex) in item.list" :key="uindex">
                  <a
                    @click="showMore(uitem)"
                    :href="uitem.link"
                    target="_blank"
                    class="footer-link"
                    >{{ uitem.title }}</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="footer-right">
            <div>
              <img src="./image/kefu.png" alt="" />
              <div>官方客服</div>
            </div>
            <div>
              <img src="./image/gzh.png" alt="" />
              <div>官方公众号</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <!-- <span>工商备案 公安备案</span> -->
        <div class="one" @click="go('ICP')">蜀ICP备2021033033号-2</div>
        <div class="two" @click="go('GW')">
          <img src="./image/jh.png" alt="" />
          <span>川公网安备51015602000652号</span>
        </div>
        <div>©2023 All Rights Reserved</div>
      </div>

      <!-- 商务合作 -->
      <div class="swWorker" v-show="show_swWorker">
        <div class="bg" @click="showMore('close')">
          <img src="./image/swhz.png" alt="" />
        </div>
      </div>

      <!-- 服务协议 -->
      <el-drawer
        title="服务协议"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <div class="container">
          <div class="title">
            <h5>软件风险协议</h5>
          </div>
          <div class="agreement">
            <div class="subtitle">期魔方实盘接入用户协议</div>
            <div>
              感谢您选择使用期魔方量化交易平台（以下简称“本平台”）。在您使用本平台提供的服务之前，请仔细阅读以下用户协议（以下简称“本协议”）。本协议是您与本平台之间就使用本平台所提供的所有服务所达成的法律协议。
              通过点击“同意并继续”按钮，您表示您已经阅读、理解并同意接受本协议中的所有条款，并同意接受平台提供的各项服务。
            </div>
          </div>
          <hr />
          <div>
            <div class="itemTitle">一、平台责任与服务</div>
            <div>
              <div class="subtitle">1.1 平台提供的服务</div>
              <div>
                本平台为您提供量化交易软件、数据服务以及必要的技术支持。您可以通过本平台访问市场数据、行情信息、历史数据，并使用量化工具进行交易。平台承诺为您提供稳定、准确的交易环境和数据服务，尽力保证系统的正常运行和交易的顺利执行。
              </div>
            </div>
            <div>
              <div class="subtitle">1.2 技术支持与维护</div>
              <div>
                平台将为您提供持续的技术支持，确保交易软件和数据服务的正常使用。如果平台出现系统故障或其他问题，平台方承诺会尽最大努力及时修复，恢复服务。平台也将定期进行系统维护和升级，但不保证平台不会发生不可预见的技术问题。
              </div>
            </div>
            <div>
              <div class="subtitle">1.3 数据准确性</div>
              <div>
                平台为您提供的所有市场数据、行情信息、历史数据等，均来自于可靠的数据源。平台将采取合理措施确保数据的准确性、及时性和完整性，但不对因数据错误、延迟或不完整导致的损失承担责任。
              </div>
            </div>
            <div>
              <div class="subtitle">1.4 服务的可用性</div>
              <div>
                平台将尽力保证其服务的连续性和稳定性，但因不可抗力因素、网络故障、第三方服务提供商问题等原因，可能会导致服务中断或交易失败。平台不对因此产生的损失承担责任。
              </div>
            </div>
            <hr />
          </div>
          <div>
            <div class="itemTitle">二、交易风险提示</div>
            <div>
              <div class="subtitle">2.1 市场风险</div>
              <div>
                期货市场价格波动较大，投资者可能因市场价格波动而遭受亏损。期货交易本质上包含杠杆效应，虽然可能带来较高回报，但也增加了风险。您应充分理解并接受期货交易的风险，特别是在市场行情剧烈波动或出现突发事件时可能会导致投资亏损。
              </div>
            </div>
            <div>
              <div class="subtitle">2.2 用户策略风险</div>
              <div>
                本平台仅提供量化交易软件和数据服务，您可以通过本平台自行实现并执行自己的交易策略。由于市场环境变化、策略的执行条件、模型的假设以及历史数据的局限性，可能导致实际交易表现与回测结果不符。因此，平台不对因您自行选择、实现或调整的策略所产生的盈亏负责。
              </div>
            </div>
            <div>
              <div class="subtitle">2.3 技术风险</div>
              <div>
                本平台为您提供一个基于计算机程序和算法的交易环境，但由于网络波动、硬件故障、软件错误、交易所问题等不可预见的技术故障，可能会影响交易的顺利进行。平台承诺采取合理措施确保系统稳定，但不保证系统100%无故障，因此，由此引发的交易错误、数据丢失、系统中断等问题由平台方不承担责任。
              </div>
            </div>
            <div>
              <div class="subtitle">2.4 交易所及其他外部风险</div>
              <div>
                期货交易所的交易规则、交易暂停、市场异常波动等因素可能影响您的交易执行或价格。若交易所发生不可预见的事件，导致交易无法进行或发生其他异常，平台不对因此产生的损失承担任何责任。
              </div>
            </div>
            <hr />
          </div>
          <div>
            <div class="itemTitle">三、用户责任</div>
            <div>
              <div class="subtitle">3.1 账户信息安全</div>
              <div>
                您有责任妥善保管账户信息和登录凭证（包括用户名、密码及其他安全认证信息）。任何因您未能妥善保管账户信息导致的账户被盗用或损失，由您自行承担责任。若您发现账户存在异常，应立即通知平台方。
              </div>
            </div>
            <div>
              <div class="subtitle">3.2 策略选择与交易决策</div>
              <div>
                您有责任自行选择并实现适合的量化交易策略，设置交易参数并作出交易决策。平台提供的量化交易软件和数据服务仅供参考，平台不对您的投资决策和交易结果承担任何责任。您应根据自身的投资目标、风险承受能力以及市场动态做出合理的选择。
              </div>
            </div>
            <div>
              <div class="subtitle">3.3 合法合规使用</div>
              <div>
                您承诺在使用平台服务过程中，遵守适用的法律、法规和监管要求，不利用平台从事任何非法活动，包括但不限于操纵市场、非法交易、欺诈等行为。平台有权对任何涉嫌违法或违规的行为进行审查，并在必要时采取措施限制或终止相关账户的服务。
              </div>
            </div>
            <div>
              <div class="subtitle">3.4 风险承受能力声明</div>
              <div>
                您声明自己具备充足的期货交易知识、经验及资金，能够理解并接受期货交易可能带来的全部风险。您承诺所进行的所有交易均为自愿行为，并承担由此产生的所有风险和责任。
              </div>
            </div>
            <hr />
          </div>
          <div>
            <div class="itemTitle">四、免责条款</div>
            <div>
              <div class="subtitle">4.1 不可抗力</div>
              <div>
                由于天灾、战争、政府行为、金融市场极端波动、黑客攻击、自然灾害、系统故障或其他无法控制的事件，导致平台服务中断、交易失败或产生其他风险的，平台方不承担任何责任。
              </div>
            </div>
            <div>
              <div class="subtitle">4.2 操作失误</div>
              <div>
                由于用户操作不当、误操作或未按照平台指引进行操作导致的损失，平台方不承担任何责任。用户应自行核实每一项操作，确保策略参数和交易设置的正确性。
              </div>
            </div>
            <div>
              <div class="subtitle">4.3 第三方责任</div>
              <div>
                在使用本平台服务过程中，如因第三方提供的服务（例如：网络服务商、交易所等）导致的服务中断、数据错误等问题，平台方不对相关损失负责。
              </div>
            </div>
            <hr />
          </div>
          <div>
            <div class="itemTitle">五、协议的修改与更新</div>
            <div>
              <div class="subtitle">5.1 协议变更</div>
              <div>
                平台方有权根据需要对本协议进行修改、更新或补充。修改后的协议将在平台公布，您应定期关注相关更新。更新后的协议一旦发布即生效，并替代之前的协议版本。如果您不同意修改后的协议内容，您应停止使用平台服务；如果您继续使用平台服务，则视为您已接受修改后的协议。
              </div>
            </div>
            <hr />
          </div>
          <div>
            <div class="itemTitle">六、法律适用与争议解决</div>
            <div>
              <div class="subtitle">6.1 法律适用</div>
              <div>
                本协议的解释、适用及争议解决均应适用中华人民共和国的法律。
              </div>
            </div>
            <div>
              <div class="subtitle">6.2 争议解决</div>
              <div>
                如发生争议，双方应首先通过友好协商解决；如协商不成，任何一方可提交至平台方所在地有管辖权的法院提起诉讼。
              </div>
            </div>
            <hr />
          </div>
          <div class="button">
            <!-- <el-checkbox v-model="agreed"> 我已阅读并同意上述条款 </el-checkbox>
          <el-button type="primary" :disabled="!agreed" @click="onAgree">
            同意并继续
          </el-button> -->
          </div>
        </div>
      </el-drawer>
    </div>

    <!-- 移动端 -->
    <div class="Mobile">
      <div class="header">
        <div class="icon">
          <img src="./image/qmf1.png" alt="" />
          <img src="./image/qmf.png" alt="" style="margin-left: 7px" />
        </div>
        <div class="title">期魔方</div>
        <div class="menu">
          <img
            src="./image/mobile/菜单@3x.png"
            @click="click_showDropdown2"
            alt=""
          />
        </div>
      </div>
      <!-- <div
        v-show="activeIndex === 3 && showDropdown"
        class="header-nav-dropdown"
      > -->
      <div class="header-nav-dropdown" v-show="showDropdown2">
        <!-- <router-link to="/membership_mobile"> -->
        <div v-for="(item, index) in navItems" :key="index">
          <div
            @click="click_link(item, index)"
            :class="{ active: activeIndex_mobile === index }"
            :style="
              item.name === '操作文档' ||
              item.name === '策略文档' ||
              item.name === '指标文档' ||
              item.name === '机器学习'
                ? { backgroundColor: '#333333' }
                : {}
            "
          >
            {{ item.name }}
            <img
              v-show="index == 3"
              :src="showhelp ? navItemsImg[1] : navItemsImg[0]"
              alt=""
            />
          </div>

          <div class="line" v-show="index !== navItems.length - 1"></div>
        </div>
        <!-- </router-link> -->

        <!-- <router-link to="/membership_mobile">
          <div>首页</div>
          <div class="line"></div>
        </router-link>

        <div>下载安装</div>
        <div class="line"></div>
        <div>会员权益</div>
        <div class="line"></div>
        <div @click="showhelp = !showhelp">
          帮助文档
          <img :src="showhelp ? navItemsImg[1] : navItemsImg[0]" alt="" />
        </div>

        <div @click="go('cz')" v-show="showhelp" class="help">
          操作文档
          <div class="line2"></div>
        </div>

        <div @click="go('cl')" v-show="showhelp" class="help">
          策略文档
          <div class="line2"></div>
        </div>

        <div @click="go('zb')" v-show="showhelp" class="help">
          指标文档
          <div class="line2"></div>
        </div>

        <div @click="go('jq')" v-show="showhelp" class="help">
          机器学习
          <div class="line2"></div>
        </div>

        <div>社区论坛</div>
        <div class="line"></div>
        <div>关于我们</div> -->
      </div>

      <!-- <Home_Mobile>1111</Home_Mobile> -->
      <!-- 路由出口 -->
      <router-view v-if="isPhone"></router-view>

      <!-- 移动端 侧边导航栏 -->
      <div class="right_nav">
        <div class="right_nav_item" @click="click_right_nav('微信咨询')">
          <div>
            <img src="./image/right_nav/微信@3x.png" alt="" />
          </div>
          <!-- <div>微信咨询</div> -->
        </div>
        <div class="right_nav_item" @click="click_right_nav('QQ咨询')">
          <div>
            <img src="./image/right_nav/qq@3x.png" alt="" />
          </div>
          <!-- <div>QQ咨询</div> -->
        </div>
        <div class="right_nav_item" @click="click_right_nav('商务合作')">
          <div>
            <img src="./image/right_nav/商务合作@3x.png" alt="" />
          </div>
          <!-- <div>商务合作</div> -->
        </div>
        <div class="right_nav_item" @click="click_right_nav('联系电话')">
          <div>
            <img src="./image/right_nav/侧边电话@3x.png" alt="" />
          </div>
          <!-- <div>联系电话</div> -->
        </div>
      </div>

      <div class="right_nav_wx" v-show="showWX">
        <img src="./image/right_nav/微信.png" alt="" />
        <div class="title">官方微信客服</div>
      </div>
      <div class="right_nav_content" v-show="showCooperate">
        <div>
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAlign"
            :rules="rules"
            ref="formRef"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wx">
              <el-input v-model="formLabelAlign.wx"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="formLabelAlign.phone"></el-input>
            </el-form-item>
            <el-form-item label="主体类型" prop="subjectType">
              <!-- <el-input v-model="formLabelAlign.subjectType"></el-input> -->
              <el-select
                v-model="formLabelAlign.subjectType"
                placeholder="请选择主体类型"
              >
                <el-option label="个人" value="个人"></el-option>
                <el-option label="期货公司" value="期货公司"></el-option>
                <el-option label="销售团队" value="销售团队"></el-option>
                <el-option label="投教老师" value="投教老师"></el-option>
                <el-option label="基金公司" value="基金公司"></el-option>
                <el-option label="证券公司" value="证券公司"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <button
          type="primary"
          v-loading="loading"
          @click="submitForm('mobile')"
        >
          立即预约
        </button>
      </div>
      <div class="right_nav_qq" v-show="showQQ">
        <img src="./image/right_nav/qq(1).png" alt="" />
      </div>
      <div class="right_nav_phone" v-show="showPhone">
        <div>13880072820</div>
        <div>
          <img @click="copy" src="./image/right_nav/复制@3x.png" alt="" />
        </div>
      </div>

      <div class="footer">
        <div class="footer-center">
          <div v-for="(item, index) in footerList" :key="index">
            <div class="footer-center-item">
              <div class="footer-title">{{ item.title }}</div>
              <div v-for="(uitem, uindex) in item.list" :key="uindex">
                <a
                  @click="showMore(uitem, 'mobile')"
                  :href="uitem.link"
                  target="_blank"
                  class="footer-link"
                  >{{ uitem.title }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="footer-content">
          <div class="footer-left">
            <div class="footer-left-title">
              <div class="footer-left-icon">
                <img src="./mobile/image/电话@3x.png" alt="" />
              </div>
              <div>
                <div class="footer-left-phone">028-60119342</div>
                <div class="footer-left-time">
                  <span>工作日:</span> <span>9:00</span><span>~</span>18:00
                </div>
              </div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
              <button @click="clickDownload('mobile')" class="button1">
                软件下载
              </button>
              <button @click="open_showCooperate" class="button2">
                申请试用
              </button>
            </div>
          </div>

          <div class="footer-right">
            <div>
              <img src="./image/kefu.png" alt="" />
              <div>官方客服</div>
            </div>
            <div>
              <img src="./image/gzh.png" alt="" />
              <div>官方公众号</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="one" @click="go('ICP')">蜀ICP备2021033033号-2</div>
        <div class="two" @click="go('GW')">
          <img src="./image/jh.png" alt="" />
          <span>川公网安备51015602000652号</span>
        </div>
        <div>©2023 All Rights Reserved</div>
      </div>

      <!-- 商务合作 -->
      <div class="swWorker" v-show="show_swWorker">
        <div class="bg" @click="showMore('close')">
          <img src="./image/swhz.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Home from "./pages/home.vue";
// import Download from "./pages/download.vue";
// import Membership from "./pages/membership.vue";
// import About from "./pages/about.vue";

// import Home_Mobile from "./mobile/pages/home.vue";

import axios from "axios";

export default {
  name: "App",
  components: {
    // HelloWorld
    // Home,
    // Download,
    // Membership,
    // About,
    // Home_Mobile,
  },

  data() {
    return {
      direction: "rtl",
      drawer: false,

      labelPosition: "top",
      formLabelAlign: {
        name: "",
        wx: "",
        phone: "",
        subjectType: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur",
          },
        ],
        subjectType: [
          { required: true, message: "请输入主体类型", trigger: "change" },
        ],
      },

      activeIndex: 0, // 当前激活的导航项索引
      showIndex: 0, // 当前显示的页面索引
      currentContentIndex: 0, // 当前显示的内容组件索引
      activeIndex_mobile: 0, // 移动端当前激活的导航项索引
      // navItems: [
      //   { name: "首页", path: "/home_mobile" },
      //   { name: "下载安装", path: "/download_mobile" },
      //   { name: "会员权益", path: "/membership_mobile" },
      //   // { name: "帮助文档▾", path: "/help" },
      //   { name: "帮助文档", path: "/help" },
      //   { name: "社区论坛", path: "/forum" },
      //   { name: "关于我们", path: "/about_mobile" },
      //   // { name: "百度", path: "https://www.baidu.com" }, // 外部链接
      // ],

      navItems: [
        { name: "首页", path: "/" },
        { name: "下载安装", path: "/download" },
        { name: "会员权益", path: "/membership" },
        // { name: "帮助文档▾", path: "/help" },
        { name: "帮助文档", path: "/help" },
        { name: "社区论坛", path: "/forum" },
        { name: "关于我们", path: "/about" },
        // { name: "百度", path: "https://www.baidu.com" }, // 外部链接
      ],

      navItemsImg: [
        require("./image/三角-下@3x.png"),
        require("./image/三角-上@3x.png"),
      ],

      showDropdown: false, // 是否显示下拉菜单
      showDropdown2: false, // 是否显示下拉菜单  移动端
      showhelp: false, // 是否显示帮助文档

      footerList: [
        {
          title: "解决方案",
          list: [
            {
              title: "操作文档",
              link: "https://www.qmfquant.com/app/index.html",
            },
            {
              title: "指标编写",
              link: "https://qmfquant.com/static/doc/code/indicatorEdit.html",
            },
            {
              title: "策略编写",
              link: "https://qmfquant.com/static/doc/code/strategyEdit.html",
            },
            {
              title: "机器学习",
              link: "https://qmfquant.com/static/doc/code/modelEdit.html",
            },
          ],
        },
        {
          title: "会员权益",
          list: [
            {
              title: "会员对比",
              link: "https://academy.qmfquant.com/archives/7920",
            },
            {
              title: "在线购买",
              link: "https://academy.qmfquant.com/archives/7920",
            },
            {
              title: "商务合作",
              // link: "https://www.baidu.com",
            },
          ],
        },
        {
          title: "服务支持",
          list: [
            {
              title: "魔方商学院",
              link: "https://academy.qmfquant.com/",
            },
            {
              title: "服务协议",
              // link: "../public/riskProtocols.html",
            },
            {
              title: "在线支持",
              // link: "https://www.baidu.com",
            },
          ],
        },
      ],

      showWX: false,
      showQQ: false,
      showCooperate: false,
      showPhone: false,

      show_swWorker: false,

      loading: false,

      isPhone: false,
    };
  },

  mounted() {
    // 组件挂载后添加事件监听
    console.log("生命周期钩子");
    document.addEventListener("click", this.handleClickOutsideFramed);

    setTimeout(() => {
      // 获取屏幕宽度
      const screenWidth = window.screen.width;
      // console.log(screenWidth);
      if (screenWidth < 769) {
        // this.$router.push("/home_mobile");
        // this.click_link({ name: "首页", path: "/home_mobile" }, 0);

        this.isPhone = true;
      } else {
        // this.$router.push("/home");

        this.isPhone = false;

        // 移动端分享服务协议地址PC端访问会自动跳转到首页
        // console.log(this.$route.path);
        if (this.$route.path == "/riskProtocols") {
          // console.log(this.$route.path, this.linkService);
          this.$router.push("/").then(() => {
            let ishome = this.$route.path == "/" ? true : false;
            // console.log(ishome);

            if (ishome) {
              // window.scrollTo({
              //   top: document.body.scrollHeight, // 滚动到页面底部
              //   behavior: "smooth", // 平滑滚动效果
              // });
              setTimeout(() => {
                this.showMore(
                  {
                    title: "服务协议",
                    // link: "../public/riskProtocols.html",
                  },
                  ""
                );
              }, 500);
            }
          });
        }
      }
      // console.log(this.isPhone);
    }, 200);
  },
  beforeUnmount() {
    // 组件销毁前移除事件监听
    document.removeEventListener("click", this.handleClickOutsideFramed);
  },
  methods: {
    handleClickOutsideFramed(event) {
      // console.log(event.target);
      if (
        !event.target.classList.contains("right_nav_content") &&
        !event.target.classList.contains("right_nav_wx") &&
        !event.target.classList.contains("right_nav_qq") &&
        !event.target.classList.contains("right_nav_phone") &&
        !event.target.classList.contains("right_nav") &&
        !event.target.classList.contains("button2") &&
        !event.target.classList.contains("header_box") &&
        !event.target.classList.contains("header-nav-dropdown") &&
        !event.target.closest(".right_nav_content") &&
        !event.target.closest(".right_nav_wx") &&
        !event.target.closest(".right_nav_qq") &&
        !event.target.closest(".right_nav_phone") &&
        !event.target.closest(".button2") &&
        !event.target.closest(".header_box") &&
        !event.target.closest(".header-nav-dropdown") &&
        !event.target.closest(".right_nav")
      ) {
        // console.log('点击right_nav_content以外的盒子');

        this.showWX = false;
        this.showQQ = false;
        this.showCooperate = false;
        this.showPhone = false;

        this.showDropdown = false; // 显示/隐藏下拉菜单
      }
    },

    click_right_nav(type) {
      console.log(type);
      switch (type) {
        case "微信咨询":
          this.showQQ = false;
          this.showCooperate = false;
          this.showPhone = false;
          this.showWX = !this.showWX;
          break;
        case "QQ咨询":
          this.showWX = false;
          this.showCooperate = false;
          this.showPhone = false;
          this.showQQ = !this.showQQ;
          break;
        case "商务合作":
          this.showWX = false;
          this.showQQ = false;
          this.showPhone = false;
          this.showCooperate = !this.showCooperate;
          break;
        case "联系电话":
          this.showWX = false;
          this.showCooperate = false;
          this.showQQ = false;
          this.showPhone = !this.showPhone;
          break;

        default:
          break;
      }
    },

    go(type) {
      console.log(type);
      // window.open('/public/riskProtocols.html')
      if (type == "cz") {
        window.open("https://www.qmfquant.com/app/index.html");
      } else if (type == "cl") {
        window.open("https://qmfquant.com/static/doc/code/strategyEdit.html");
      } else if (type == "zb") {
        window.open("https://qmfquant.com/static/doc/code/indicatorEdit.html");
      } else if (type == "jq") {
        window.open("https://qmfquant.com/static/doc/code/modelEdit.html");
      }

      if (type == "ICP") {
        window.open("https://beian.miit.gov.cn/#/Integrated/index");
      } else if (type == "GW") {
        window.open("https://beian.mps.gov.cn/#/query/webSearch");
      }
    },

    async copy() {
      const phoneNumberDiv = document.querySelector(".right_nav_phone div");
      // console.log(phoneNumberDiv.textContent);
      
      try {
        await navigator.clipboard.writeText(phoneNumberDiv.textContent);
        alert("联系电话已复制");
      } catch (err) {
        console.error("无法复制：", err);
      }
    },
    clickDownload(type) {
      // console.log(type);
      if (type == "mobile") {
        alert("请在电脑端下载安装");
        return;
      }

      location.href = "https://academy.qmfquant.com/qimofang.zip";
    },
    open_showCooperate() {
      this.showCooperate = true;
    },

    toggleItem(index) {
      console.log(index, this.showDropdown);

      if (index === 3) {
        // 切换“帮助文档▾”和“帮助文档▴”
        // this.navItems[index].name =
        //   this.navItems[index].name === "帮助文档▾" ? "帮助文档▴" : "帮助文档▾";
        this.activeIndex = index;
        this.showDropdown = !this.showDropdown; // 显示/隐藏下拉菜单
      } else if (index === 4) {
        // 对于“社区论坛”，仅切换导航栏样式，不切换页面内容
        this.activeIndex = index; // 激活当前导航项
        this.showDropdown = false; // 隐藏下拉菜单
        // this.navItems[3].name = "帮助文档▾";

        // window.open("https://academy.qmfquant.com/");
        window.open("https://academy.qmfquant.com/forums-2");
      } else {
        // this.navItems[3].name = "帮助文档▾";
        this.activeIndex = index;

        this.showDropdown = false; // 隐藏下拉菜单

        this.currentContentIndex = index; // 更新当前显示的内容组件索引

        // PC端路由跳转
        if (index == 0) {
          if (this.$route.path !== "/") {
            this.$router.push("/");
          }
        } else if (index == 1) {
          if (this.$route.path !== "/download") {
            this.$router.push("/download");
          }
        } else if (index == 2) {
          if (this.$route.path !== "/membership") {
            this.$router.push("/membership");
          }
        } else if (index == 5) {
          if (this.$route.path !== "/about") {
            this.$router.push("/about");
          }
        }
      }

      if (this.activeIndex != 3 && this.activeIndex != 4) {
        localStorage.setItem("activeIndex", this.activeIndex);
        this.showIndex = localStorage.getItem("activeIndex");
        console.log(this.showIndex);
      }
    },

    submitForm(type) {
      console.log(this.formLabelAlign);
      const name = this.formLabelAlign.name;
      const wx = this.formLabelAlign.wx;
      const phone = this.formLabelAlign.phone;
      const subjectType = this.formLabelAlign.subjectType;

      if (this.loading) {
        if (type === "mobile") {
          alert("正在预约中，请不要重复提交预约");
        } else {
          this.$notify({
            title: "提示",
            message: "正在预约中，请不要重复提交预约",
            type: "warning",
          });
        }
        // this.$notify({
        //   title: "提示",
        //   message: "正在预约中，请不要重复提交预约",
        //   type: "warning",
        // });
        return;
      }
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // alert("表单校验通过！");
          this.loading = true;

          axios
            // .post("https://academy.qmfquant.com/website/appointmentApply", {
            // .post("http://203.195.246.76:13331/website/appointmentApply", {
            .post("https://www.qmfquant.com/website/appointmentApply", {
              name: name,
              wechat: wx,
              phone: phone,
              appointmentType: subjectType,
            })
            .then((res) => {
              console.log(res);
              this.loading = false;

              if (type === "mobile") {
                // console.log("移动端提交");
                // alert("提交预约申请成功，我们将尽快与您联系");
                alert(res.data.msg);
              } else {
                this.$notify({
                  title: "预约成功",
                  message: res.data.msg,
                  type: "success",
                });
              }
            })
            .catch((err) => {
              console.log(err);

              if (type === "mobile") {
                alert(err.data.msg);
              } else {
                this.$notify.error({
                  title: "预约失败",
                  message: err.data.msg,
                });
              }
            });
        } else {
          // alert("表单校验失败！");
        }
      });
    },

    showMore(uitem, type) {
      console.log(uitem.title, type);
      if (uitem == "close") {
        this.show_swWorker = false;
      } else if (uitem.title == "商务合作") {
        this.show_swWorker = true;
        console.log(this.show_swWorker);
      } else if (uitem.title == "服务协议") {
        // console.log(uitem.title);
        // window.open('/public/riskProtocols.html', '_blank')
        // console.log(this.$router.push('/riskProtocols'));
        // this.$router.push('/riskProtocols'); // 使用编程式导航

        // this.drawer = true;
        if (type === "mobile") {
          window.scrollTo({
            // top: document.body.scrollHeight, // 滚动到页面底部
            top: 0, // 滚动到页面底部
            behavior: "smooth", // 平滑滚动效果
          });

          // if (this.$route.fullPath == "/riskProtocols_mobile") {
          //   return;
          // }
          // // console.log(this.$route);
          // this.$router.push("/riskProtocols_mobile");

          if (this.$route.fullPath == "/riskProtocols") {
            return;
          }
          // console.log(this.$route);
          this.$router.push("/riskProtocols");
        } else {
          this.drawer = true;
        }
      } else if (uitem.title == "在线支持") {
        window.scrollTo({
          // top: document.body.scrollHeight, // 滚动到页面底部
          top: 0, // 滚动到页面底部
          behavior: "smooth", // 平滑滚动效果
        });
        // console.log(this.$route.name);
        if (type == "mobile") {
          // if (this.$route.fullPath == "/about_mobile") {
          //   return;
          // }
          // // console.log(this.$route);
          // // this.$router.push("/about");
          // this.$router.push("/about_mobile");
          // // return;

          if (this.$route.fullPath == "/about") {
            return;
          }
          // console.log(this.$route);
          // this.$router.push("/about");
          this.$router.push("/about");
          // return;
        } else {
          this.toggleItem(5);
        }

        // this.activeIndex = 1;
        // this.currentContentIndex === 1
        // this.toggleItem(5);
      }
    },

    handleClose(done) {
      console.log("关闭服务协议");
      this.$confirm("确认关闭？")
        .then((res) => {
          // console.log(res);

          done();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 移动端
    click_link(item, index) {
      // console.log(item, index);
      this.activeIndex_mobile = index;
      if (item.name == "帮助文档") {
        this.showhelp = !this.showhelp;
        if (this.showhelp) {
          this.navItems.splice(
            3,
            1,
            { name: "帮助文档", path: "/" },
            { name: "操作文档", path: "/" },
            { name: "策略文档", path: "/" },
            { name: "指标文档", path: "/" },
            { name: "机器学习", path: "/" }
          );
        } else {
          this.navItems.splice(3, 5, { name: "帮助文档", path: "/help" });
        }
      } else {
        if (item.name == "操作文档") {
          window.open("https://www.qmfquant.com/app/index.html");
        } else if (item.name == "策略文档") {
          window.open("https://qmfquant.com/static/doc/code/strategyEdit.html");
        } else if (item.name == "指标文档") {
          window.open(
            "https://qmfquant.com/static/doc/code/indicatorEdit.html"
          );
        } else if (item.name == "机器学习") {
          window.open("https://qmfquant.com/static/doc/code/modelEdit.html");
        } else if (item.name == "社区论坛") {
          // window.open("https://academy.qmfquant.com/");
          window.open("https://academy.qmfquant.com/forums-2");
        } else {
          // console.log(this.$route);
          // this.$router.push(item.path);

          // 获取当前路由的完整路径
          const currentPath = this.$route.fullPath;

          // 如果目标路径与当前路径不同，则执行跳转
          if (currentPath !== item.path) {
            this.$router.push(item.path);

            this.showDropdown2 = false;
          }
        }
      }
    },
    click_showDropdown2() {
      console.log(this.showDropdown2);

      this.showDropdown2 = !this.showDropdown2;
    },
  },
};
</script>

<style scoped lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

// @media (min-width: 769px) and (max-width: 1200px) {
@media (min-width: 769px) {
  .Mobile {
    display: none;
  }

  .header_box {
    width: 100%;
    height: 79px;
    background: #0b0004;
    border-bottom: 1px solid rgba(51, 51, 51, 1);
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999 !important;
  }
  .header {
    left: 0px;
    top: 0px;
    /* width: 1920px; */
    width: 1400px;
    margin: 0 auto;
    font-size: 18px;
    height: 79px;
    opacity: 1;
    background: #0b0004;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 160px;
    // z-index: 999;
    // // position: relative;
    // position: fixed;
    // top: 0;
    // right: 0;
    // z-index: 9999;
  }

  .header-logo img:first-child {
    left: 160px;
    top: 20px;
    width: 35.03px;
    height: 40px;
    opacity: 1;
    /* background: url(https://img.js.design/assets/img/67a475612bd8fa167e65daaa.png); */
  }
  .header-logo img {
    left: 202.44px;
    top: 31.51px;
    width: 64.56px;
    height: 22.93px;
    opacity: 1;
    /* background: url(https://img.js.design/assets/img/67a47561b269b2492dffcd3a.png); */
  }
  .header-nav {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
  .header-nav div:first-child {
    width: 39px;
    height: 33px;
    opacity: 0.5;
    letter-spacing: 0px;
    line-height: 34.75px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
    display: flex;
    cursor: pointer;
    position: relative;
  }
  .header-nav div {
    // width: 118px;
    height: 33px;
    opacity: 0.5;
    letter-spacing: 0px;
    line-height: 34.75px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
    display: flex;
    cursor: pointer;
    position: relative;
    img {
      width: 10px;
      height: 6px;
      margin-top: 16px;
      margin-left: 6px;
    }
  }
  .header-nav div.active {
    color: white !important; /* 激活状态的字体颜色为白色 */
    opacity: 1; /* 确保激活状态的字体颜色不会被透明度影响 */
  }
  .header-nav div.active::after {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 4px;
    background-color: #c20d22;
  }
  .header-nav-dropdown {
    position: fixed;
    top: 79px;
    right: 346px;
    width: 160px;
    height: 320px;
    opacity: 0.8;
    background: rgba(0, 0, 0, 1);
    color: white !important;
    font-size: 20px;
    div {
      width: 100%;
      height: 80px;
      line-height: 80px;
      text-align: center;
      cursor: pointer;
      color: white !important;
      z-index: 999;
      position: sticky;
      // background: rgba(0, 0, 0, 1) !important;
    }
    .line {
      width: 100px;
      height: 1px;
      background-color: #363334;
      margin-left: 30px;
    }
  }

  // 侧边导航栏
  .app {
    // position: relative;
    .right_nav {
      // position: absolute;
      position: fixed;
      z-index: 1000;
      // top: 483px;
      top: 300px;
      right: 20px;
      // width: 88px;
      // height: 100px;
      // opacity: 1;
      // background: rgba(35, 26, 34, 1);
      .right_nav_item {
        width: 88px;
        height: 90px;
        opacity: 1;
        background: rgba(35, 26, 34, 1);
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        // align-items: center;
        // padding-top: 22px;
        padding-top: 10px;
        box-sizing: border-box;
        // cursor: pointer;

        img {
          // width: 30px;
          // width: 36px;
          width: 40px;
          height: 40px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
        }
        div {
          width: 88px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(255, 255, 255, 1);
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .right_nav_wx {
      position: fixed;
      top: 300px;
      right: 128px;
      // width: 400px;
      width: 260px;
      // height: 524px;
      height: 290px;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 30px;
      box-sizing: border-box;
      z-index: 1000;
      img {
        // width: 320px;
        width: 200px;
      }
      div {
        color: #918d91;
        font-size: 20px;
        text-align: center;
        margin-top: 10px;
      }
    }
    .right_nav_qq {
      position: fixed;
      top: 300px;
      right: 128px;
      width: 260px;
      height: 350px;
      // height: 400px;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 30px;
      box-sizing: border-box;
      z-index: 1000;
      img {
        width: 200px;
        // width: 400px;
      }
    }
    .right_nav_content {
      position: fixed;
      top: 300px;
      right: 128px;
      width: 400px;
      height: 524px;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 40px;
      box-sizing: border-box;
      z-index: 1000;
      // .el-form-item {
      //   // width: 320px;
      //   // height: 40px;
      //   .el-input__inner {
      //     background-color: #c20d22 !important;
      //   }
      // }
      /* 在组件的样式文件中 */
      .el-form-item {
        ::v-deep .el-input__inner {
          background: rgba(255, 255, 255, 0.1) !important;
          border: none;
          width: 321px;
          color: #fff;
        }
        ::v-deep .el-form-item__label {
          color: #fff !important;
          padding: 0 0 0 8px;
        }
        margin-bottom: 10px;
      }

      button {
        width: 321px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        // background: linear-gradient(
        //   130.68deg,
        //   rgba(194, 13, 34, 1) 0%,
        //   rgba(255, 180, 91, 1) 100%
        // );
        // border: 2px solid rgba(255, 255, 255, 1);

        border: none;
        background-image: url("./image/right_nav/立即预约@3x.png");
        background-position: center;
        background-size: cover;

        cursor: pointer;
        margin-top: 30px;
        color: white;
      }
    }

    .right_nav_phone {
      position: fixed;
      // top: 300px;
      top: 570px;
      right: 128px;
      width: 260px;
      height: 90px;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 30px 30px;
      box-sizing: border-box;
      z-index: 1000;

      // color: #918d91;
      color: #fff;
      font-size: 23px;
      text-align: center;
      display: flex;
      justify-content: center;
      img {
        width: 20px;
        // width: 400px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }

  .footer {
    padding-top: 18px;
    width: 100%;
    height: 260px;
    opacity: 1;
    background: #06010e;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-content {
      width: 1400px;
      display: flex;
      justify-content: space-between;
    }
    .footer-left {
      width: 226px;
      color: white;
      .footer-left-title {
        display: flex;
        margin-bottom: 40px;
      }
      .footer-left-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #c20d22;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
      }
      .footer-left-phone {
        font-size: 20px;
        margin-bottom: 4px;
      }
      .footer-left-time {
        color: #838087;
        font-size: 14px;
      }
      .button1 {
        width: 186px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0);
        color: #ffffff;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 1);
        margin-bottom: 20px;
        font-size: 18px;
      }
      .button2 {
        width: 186px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        color: #000000;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 1);
        font-size: 18px;
      }
    }

    .footer-center {
      margin-left: 117px;
      display: flex;
      justify-content: space-between;
      .footer-center-item {
        width: 226px;
        color: #838087;
        .footer-title {
          color: white;
          margin-bottom: 32px;
          font-size: 20px;
        }
        .footer-link {
          text-decoration: none; /* 去除下边框 */
          color: inherit; /* 继承父级颜色，去除默认的蓝色 */
          display: block; /* 换行显示 */
          margin-bottom: 15px;
          font-size: 16px;
          cursor: pointer;
        }

        .footer-link:hover {
          text-decoration: none; /* 鼠标悬停时也不显示下边框 */
          color: inherit; /* 鼠标悬停时也不改变颜色 */
        }
      }
    }

    .footer-right {
      display: flex;
      color: #838087;
      font-size: 16px;
      text-align: center;
      div:first-child {
        margin-right: 80px;
      }
      img {
        width: 130px;
        height: 130px;
        opacity: 1;
        margin-bottom: 8px;
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid rgba(51, 51, 51, 1);
    width: 100%;
    height: 40px;
    line-height: 40px;
    // text-align: center;
    opacity: 1;
    background: #06010e;
    color: #ffffff;
    font-size: 12px;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .one {
      margin-right: 20px;
      cursor: pointer;
    }
    .two {
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 26px;
        margin-right: 5px;
      }
    }
  }

  .swWorker {
    position: fixed;
    top: 0%;
    z-index: 1000;
    .bg {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: relative;
    }
    img {
      width: 400px;
      position: absolute;
      top: 30%;
      left: 40%;
    }
  }

  ::v-deep .el-drawer.rtl {
    margin-top: 80px !important;
    animation: rtl-drawer-in 0s 1ms;
    // background-color: #007bff;
    .el-drawer__header {
      color: #000000;
      margin-bottom: 20px;
      span {
        // color: #c20d22;
        font-size: 26px;
      }
    }
    .el-drawer__body {
      // background-color: #c20d22 !important;
      font-size: 22px;
    }
  }
  .container {
    width: 30%;
    // height: 840px;
    height: 87%;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 150px;
    right: 0;
    overflow-y: scroll;
    // border: 1px solid #007bff;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    h5 {
      font-weight: bold;
      font-size: 30px;
    }

    .title {
      text-align: center;
      margin-bottom: 20px;
    }

    .itemTitle {
      font-size: 20px;
      font-weight: bold;
    }

    .agreement {
      margin-bottom: 30px;
    }

    .subtitle {
      font-size: large;
      font-weight: 700;
    }

    .button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: #007bff;
      color: white;
      cursor: pointer;
      font-size: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }

    button:disabled {
      background-color: #cccccc;
    }
  }
}

@media (max-width: 769px) {
  .PC {
    display: none;
  }

  .Mobile {
    position: relative;
    // 3倍高度左右
    .header {
      width: 100vw;
      // height: 88px;
      height: 270px;
      opacity: 1;
      background: rgba(10, 0, 4, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 75px 80px;
      box-sizing: border-box;
      z-index: 999;
      position: fixed;
      .icon {
        width: 390px;
        img:first-child {
          height: 120px;
        }
        img:last-child {
          height: 69px;
          margin-bottom: 18px;
        }
      }
      .title {
        color: #fff;
        // font-size: 102px;
        font-size: 93px;
        font-weight: 500;
      }
      .menu {
        width: 390px;
        img {
          height: 180px;
          float: right;
        }
      }
    }
    .header-nav-dropdown {
      position: fixed;
      z-index: 9999;
      // position: absolute;
      top: 240px;
      right: 76px;
      width: 480px;
      // height: 960px;
      // height: 2400px;
      // opacity: 0.8;
      background: rgba(0, 0, 0, 1);
      // color: white !important;
      color: #888888;
      font-weight: 700;
      font-size: 60px;
      div {
        width: 100%;
        height: 240px;
        line-height: 240px;
        text-align: center;
        cursor: pointer;
        // color: white !important;
        z-index: 999;
        position: sticky;
        background: rgba(0, 0, 0, 1);
      }
      .line {
        width: 300px;
        height: 3px;
        background-color: #363334;
        margin-left: 90px;
      }
      .line2 {
        width: 300px;
        height: 3px;
        background-color: #5c5c5c;
        margin-left: 90px;
        position: relative;
        bottom: 6px;
      }
      img {
        width: 30px;
        margin-bottom: 2px;
      }
      .help {
        // opacity: 0.8;
        opacity: 1;
        background: rgba(51, 51, 51, 1);
      }
      .active {
        color: white !important; /* 激活状态的字体颜色为白色 */
        opacity: 1; /* 确保激活状态的字体颜色不会被透明度影响 */
      }
    }

    .right_nav {
      // position: absolute;
      position: fixed;
      z-index: 1000;
      // top: 483px;
      // top: 1100px;
      top: 1700px;
      right: 24px;
      // width: 88px;
      // height: 100px;
      // opacity: 1;
      // background: rgba(35, 26, 34, 1);
      .right_nav_item {
        width: 264px;
        height: 264px;
        border-radius: 50%;
        margin-bottom: 12px;
        opacity: 1;
        background: rgba(35, 26, 34, 1);
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        // align-items: center;
        // padding-top: 22px;
        padding-top: 80px;
        padding-left: 90px;
        box-sizing: border-box;
        // cursor: pointer;

        img {
          // width: 30px;
          // width: 36px;
          width: 120px;
          height: 120px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
        }
        div {
          width: 88px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(255, 255, 255, 1);
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .right_nav_wx {
      position: fixed;
      top: 1700px;
      right: 326px;
      width: 224px * 3;
      height: 254px * 3;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 96px;
      box-sizing: border-box;
      z-index: 1000;
      img {
        // width: 320px;
        width: 480px;
      }
      div {
        color: #918d91;
        font-size: 48px;
        font-weight: 500;
        text-align: center;
        margin-top: 10px;
      }
    }
    .right_nav_qq {
      position: fixed;
      top: 1700px;
      right: 326px;
      width: 224px * 3;
      height: 300px * 3;
      // height: 400px;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 96px;
      box-sizing: border-box;
      z-index: 1000;
      img {
        width: 480px;
        // width: 400px;
      }
    }
    .right_nav_content {
      position: fixed;
      top: 1700px;
      right: 326px;
      width: 400px * 3;
      height: 524px * 3;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      // padding: 120px 40px * 3;
      padding: 10px 120px;
      box-sizing: border-box;
      z-index: 1000;
      // .el-form-item {
      //   // width: 320px;
      //   // height: 40px;
      //   .el-input__inner {
      //     background-color: #c20d22 !important;
      //   }
      // }
      /* 在组件的样式文件中 */
      .el-form-item {
        // height: 210px;
        height: 200px;
        ::v-deep .el-input__inner {
          background: rgba(255, 255, 255, 0.1) !important;
          border: none;
          width: 320px * 3;
          height: 120px;
          color: #fff;

          font-size: 20px * 3;
          font-weight: 500;
        }
        ::v-deep .el-form-item__label {
          color: #fff !important;
          padding: 0 0 0 8px * 3;

          font-size: 20px * 3;
          font-weight: 500;
          // margin-bottom: 8px * 3;
        }
        ::v-deep .el-form-item__error {
          font-size: 16px * 3;
          font-weight: 500;
        }

        ::v-deep .el-select .el-input .el-select__caret {
          font-size: 16px * 3;
          font-weight: 500;
          // height: 120px;
          // line-height: 120px;
          width: 60px;
        }

        margin-bottom: 110px;
      }

      button {
        width: 321px * 3;
        height: 40px * 3;
        opacity: 1;
        border-radius: 4px;
        // background: linear-gradient(
        //   130.68deg,
        //   rgba(194, 13, 34, 1) 0%,
        //   rgba(255, 180, 91, 1) 100%
        // );
        // border: 2px solid rgba(255, 255, 255, 1);

        border: none;
        background-image: url("./image/right_nav/立即预约@3x.png");
        background-position: center;
        background-size: cover;

        cursor: pointer;
        margin-top: 30px;
        color: white;
      }
    }

    .right_nav_phone {
      position: fixed;
      // top: 300px;
      top: 2530px;
      right: 326px;
      width: 400px * 3;
      height: 88px * 3;
      opacity: 1;
      background: rgba(35, 26, 34, 1);
      padding: 24px * 3 80px * 3;
      box-sizing: border-box;
      z-index: 1000;

      // color: #918d91;
      color: #fff;
      font-size: 28px * 3;
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: center;
      img {
        width: 20px * 3;
        // width: 400px;
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .footer {
      width: 100vw;
      // height: 1920px;
      opacity: 1;
      background: rgba(6, 1, 14, 1);
      .footer-center {
        width: 77.2vw;
        height: 900px;
        // background-color: #007bff;
        display: flex;
        // padding: 180px 240px;
        justify-content: space-between;
        margin: 0 auto;
        .footer-center-item {
          width: 360px;
          height: 100%;
          // background-color: red;
          // margin-right: 360px;
          padding-top: 180px;
          box-sizing: border-box;
          .footer-title {
            font-size: 84px;
            color: #fff;
            margin-bottom: 30px;
          }
          .footer-link {
            text-decoration: none; /* 去除下边框 */
            color: inherit; /* 继承父级颜色，去除默认的蓝色 */
            display: block; /* 换行显示 */
            margin-bottom: 30px;
            font-size: 72px;
            color: #838087;
          }

          .footer-link:hover {
            text-decoration: none; /* 鼠标悬停时也不显示下边框 */
            color: inherit; /* 鼠标悬停时也不改变颜色 */
          }
        }
      }
      .footer-content {
        width: 93.6vw;
        margin: 0 auto;
        display: flex;
        padding-bottom: 180px;
        .footer-left {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          // width: 1020px;
          .footer-left-title {
            color: #fff;
            display: flex;
            .footer-left-icon {
              width: 120px;
              height: 120px;
              margin-right: 40px;
              img {
                width: 100%;
                height: 100%;
                position: relative;
                bottom: 56px;
              }
            }
            .footer-left-phone {
              font-size: 70px;
              margin-top: 46px;
              font-weight: 700;
            }
            .footer-left-time {
              font-size: 46px;
              font-weight: 500;
              color: #838087;

              // border: 1px solid #fff; /* 添加边框以便观察效果 */
              text-align: justify; /* 让文字两端对齐 */
              text-justify: inter-word; /* 让文字间隙均匀分布 */
              text-align-last: justify; /* 确保最后一行也两端对齐 */
              span {
                margin-right: 18px;
              }
              margin-top: 46px;
            }
          }

          .button1 {
            // width: 580px;
            width: 660px;
            height: 120px;
            opacity: 1;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0);
            color: #ffffff;
            cursor: pointer;
            border: 1px solid rgba(255, 255, 255, 1);
            margin-bottom: 40px;
            font-size: 60px;
            // margin-top: 210px;
            margin-top: 190px;
            font-weight: 500;
          }
          .button2 {
            // width: 580px;
            width: 660px;
            height: 120px;
            opacity: 1;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            color: #000000;
            cursor: pointer;
            border: 1px solid rgba(255, 255, 255, 1);
            font-size: 60px;
            font-weight: 500;
          }
        }

        .footer-right {
          display: flex;
          margin-top: 60px;
          img {
            width: 480px;
          }
          div {
            div {
              font-size: 60px;
              color: #838087;
              text-align: center;
              margin-top: 60px;
            }
          }
          div:first-child {
            margin-right: 120px;
          }
        }
      }
    }

    .footer-bottom {
      width: 100vw;
      height: 180px;
      opacity: 1;
      background: rgba(6, 1, 14, 1);
      color: #ffffff;
      font-size: 42px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .two {
        line-height: 180px;
        img {
          width: 80px;
        }
      }
      border-top: 2px solid #838087;
    }

    .swWorker {
      position: fixed;
      top: 0%;
      z-index: 1000;
      .bg {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: relative;
      }
      img {
        width: 1200px;
        position: absolute;
        // top: 30%;
        // left: 40%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  // 移动端下拉框样式
  .el-select-dropdown {
    height: 200px;
  }
  .el-select-dropdown__item {
    font-size: 20px * 3;
    font-weight: 500;
    height: 120px;
    line-height: 120px;
  }
  // .el-scrollbar {
  //   ::v-deep .el-select-dropdown__wrap {
  //     // max-height: initial !important;
  //     max-height: 1200px;
  //   }
  // }
  .el-select-dropdown__wrap {
    background-color: red !important;
  }
}
</style>
