/*
 * @Author: mengteng 1802201878@qq.com
 * @Date: 2025-02-25 14:24:16
 * @LastEditors: mengteng 1802201878@qq.com
 * @LastEditTime: 2025-03-18 10:16:24
 * @FilePath: \vue_phone - 副本 - 副本\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import { isMobile } from '@/utils/deviceUtils'; // 引入 isMobile 函数

// import Home from '../pages/home.vue'; // 首页组件
// import Download from '../pages/download.vue'; // 下载页组件
// import Membership from '../pages/membership.vue'; // 会员页组件
// import About from '../pages/about.vue'; // 关于我们页组件

// import RiskProtocols from '../pages/riskProtocols.vue'; // 新页面组件

// import Home_Mobile from '../mobile/pages/home.vue'; // 移动端首页组件
// import Download_Mobile from '../mobile/pages/download.vue'; // 移动端下载页组件
// import Membership_Mobile from '../mobile/pages/membership.vue'; // 移动端会员页组件
// import About_Mobile from '../mobile/pages/about.vue'; // 移动端关于我们页组件

// import RiskProtocols_Mobile from '../mobile/pages/riskProtocols.vue'; // 移动端风险协议页组件

Vue.use(Router);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => (isMobile() ? import('../mobile/pages/home.vue') : import('../pages/home.vue'))
	},
	{
		path: '/download',
		name: 'Download',
		component: () => (isMobile() ? import('../mobile/pages/download.vue') : import('../pages/download.vue'))
	},
	{
		path: '/membership',
		name: 'Membership',
		component: () => (isMobile() ? import('../mobile/pages/membership.vue') : import('../pages/membership.vue'))
	},
	{
		path: '/about',
		name: 'About',
		component: () => (isMobile() ? import('../mobile/pages/about.vue') : import('../pages/about.vue'))
	},
	{
		path: '/riskProtocols',
		name: 'RiskProtocols',
		component: () =>
			isMobile() ? import('../mobile/pages/riskProtocols.vue') : import('../pages/riskProtocols.vue')
	}
];

// const routes = [
// 	// {
// 	// 	path: '/',
// 	// 	name: 'Home',
// 	// 	component: Home
// 	// },
//   {
// 		path: '/',
// 		name: 'Home_Mobile',
// 		component: Home_Mobile
// 	},
// 	{
// 		path: '/riskProtocols',
// 		name: 'RiskProtocols',
// 		component: RiskProtocols
// 	},

// 	{
// 		path: '/home_mobile',
// 		name: 'Home_Mobile',
// 		component: Home_Mobile
// 	},
// 	{
// 		path: '/download_mobile',
// 		name: 'Download_Mobile',
// 		component: Download_Mobile
// 	},
// 	{
// 		path: '/membership_mobile',
// 		name: 'Membership_Mobile',
// 		component: Membership_Mobile
// 	},
// 	{
// 		path: '/about_mobile',
// 		name: 'About_Mobile',
// 		component: About_Mobile
// 	},

// 	{
// 		path: '/riskProtocols_mobile',
// 		name: 'RiskProtocols_Mobile',
// 		component: RiskProtocols_Mobile
// 	}
// ];

const router = new Router({
	mode: 'history', // 使用 HTML5 history 模式
	// mode: 'hash', // 使用 HTML5 history 模式
	base: process.env.BASE_URL,
	routes
});

export default router;
