/*
 * @Author: mengteng 1802201878@qq.com
 * @Date: 2024-12-20 10:18:52
 * @LastEditors: mengteng 1802201878@qq.com
 * @LastEditTime: 2025-03-06 17:10:55
 * @FilePath: \vue_phone\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 导入js文件，根据窗口调整html-font-size大小
import 'amfe-flexible'
import './assets/css/style.css'
import './assets/iconfont/iconfont.css'

import router from './router'; // 引入路由配置

// import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css'; // 引入 video.js 的默认样式
// import 'vue-video-player/src/custom-theme.css'; // 可选：自定义主题样式

// Vue.use(VideoPlayer);



Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.use(router);

Vue.config.errorHandler = function (err, vm, info) {
  if (err.message.includes("Cannot GET") || err.message.includes("404 (Not Found)")) {
    console.log("忽略的错误：", err.message, vm, info);
  } else {
    // 其他错误仍然正常输出
    console.error(err);
  }
};
// 在 main.js 或入口文件中
window.onerror = function (message, source, lineno, colno, error) {
  // 检查错误信息是否包含特定内容
  if (message.includes("Cannot GET") || message.includes("404 (Not Found)")) {
    console.log("忽略的错误：", message, source, lineno, colno, error);
    return true; // 返回 true 表示已处理错误，不会在控制台显示
  }
  // 其他错误仍然正常输出
  return false;
};

new Vue({
  router, // 使用路由

  render: h => h(App),
}).$mount('#app')
