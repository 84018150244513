/*
 * @Author: mengteng 1802201878@qq.com
 * @Date: 2025-03-18 09:54:15
 * @LastEditors: mengteng 1802201878@qq.com
 * @LastEditTime: 2025-03-18 09:54:25
 * @FilePath: \vue_phone - 副本 - 副本\src\utils\deviceUtils.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// src/utils/deviceUtils.js
export function isMobile() {
    // 使用正则表达式检测是否为移动端设备
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }